import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PageHeader from './PageHeader.js'
import PageSection from './PageSection.js'

import { setContactSelected } from '../redux/contactsReducer'

import { AttributeTable, AttributeRow } from './CommonStylesTables'

function Contact(props) {
  const dispatch = useDispatch()

  const contacts = useSelector((state) => state.contacts.contacts)
  const contactSelected = useSelector((state) => state.contacts.contactSelected)

  const contactId = props.contactId

  useEffect(() => {
    if (contacts) {
      for (let i = 0; i < contacts.length; i++) {
        if (contacts[i].contact_id === parseInt(contactId)) {
          dispatch(setContactSelected(contacts[i]))
          break
        }
      }
    }
  }, [contacts, contactId, dispatch])

  return (
    <>
      <div id="contact">
        <PageHeader
          title={'Contact Details: ' + (contactSelected.label || '')}
        />
        <PageSection>
          <h2>General Information</h2>
          <AttributeTable>
            <tbody>
              <AttributeRow>
                <th>Contact ID:</th>
                <td>{contactSelected.contact_id || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Connection Status:</th>
                <td>
                  {contactSelected.Connections !== undefined
                    ? contactSelected.Connections[0].state || ''
                    : ''}
                </td>
              </AttributeRow>
            </tbody>
          </AttributeTable>
          <h2>Contact Information</h2>
          <AttributeTable>
            <tbody>
              <AttributeRow>
                <th>Name:</th>
                <td>{contactSelected.label || ''}</td>
              </AttributeRow>
            </tbody>
          </AttributeTable>
        </PageSection>
      </div>
    </>
  )
}

export default Contact
