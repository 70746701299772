import { DateTime } from 'luxon'
import React from 'react'
import { useSelector } from 'react-redux'

import PageHeader from './PageHeader.js'
import PageSection from './PageSection.js'

import { AttributeTable, AttributeRow } from './CommonStylesTables'

function Presentation(props) {
  const contacts = useSelector((state) => state.contacts.contacts)
  const presentationId = props.presentation
  const presentationReports = useSelector(
    (state) => state.presentations.presentationReports
  )

  let presentationSelected = ''

  for (let i = 0; i < presentationReports.length; i++) {
    if (presentationReports[i].presentation_exchange_id === presentationId) {
      presentationSelected = presentationReports[i]
      break
    }
  }

  // (AmmonBurgi) Find connection and give the selected presentation a contact_label.
  for (let i = 0; i < contacts.length; i++) {
    if (
      contacts[i].Connections[0].connection_id ===
      presentationSelected.connection_id
    ) {
      presentationSelected.contact_label = contacts[i].label
      break
    }
  }

  if (presentationSelected !== '') {
    let attributes = {}
    const requestName = JSON.parse(presentationSelected.presentation_request)
      .name

    if (presentationSelected.presentation) {
      const presentationRequestedProof = JSON.parse(
        presentationSelected.presentation
      ).requested_proof

      if (
        presentationRequestedProof.self_attested_attrs ||
        presentationRequestedProof.revealed_attrs
      ) {
        attributes = {
          ...presentationRequestedProof.self_attested_attrs,
          ...presentationRequestedProof.revealed_attrs,
        }
      }
    }

    if (
      attributes[Object.keys(attributes)[0]] &&
      attributes[Object.keys(attributes)[0]].raw !== null &&
      attributes[Object.keys(attributes)[0]].raw !== undefined
    ) {
      for (const attribute in attributes) {
        attributes[attribute] = attributes[attribute].raw
      }
    }

    let handleAttrDisplay = null

    if (presentationSelected.presentation) {
      handleAttrDisplay = () => {
        if (attributes.recovery_score) {
          const dataDate = attributes.data_date
            ? DateTime.fromSeconds(
                parseInt(attributes.data_date)
              ).toLocaleString()
            : ''
          const issuanceDate = attributes.issuance_date
            ? DateTime.fromSeconds(
                parseInt(attributes.issuance_date)
              ).toLocaleString()
            : ''

          return (
            <>
              <h2>Presentation Attributes</h2>
              <AttributeTable>
                <tbody>
                  <AttributeRow>
                    <th>Recovery Score:</th>
                    <td>{attributes.recovery_score || ''}</td>
                  </AttributeRow>
                  <AttributeRow>
                    <th>Resting Heart Rate:</th>
                    <td>{attributes.resting_heart_rate || ''}</td>
                  </AttributeRow>
                  <AttributeRow>
                    <th>Heart Rate Variability:</th>
                    <td>{attributes.heart_rate_variability || ''}</td>
                  </AttributeRow>
                  <AttributeRow>
                    <th>Respiratory Rate:</th>
                    <td>{attributes.respiratory_rate || ''}</td>
                  </AttributeRow>
                  <AttributeRow>
                    <th>Sleep Duration:</th>
                    <td>{attributes.sleep_duration || ''}</td>
                  </AttributeRow>
                  <AttributeRow>
                    <th>Data Date:</th>
                    <td>{dataDate || ''}</td>
                  </AttributeRow>
                  <AttributeRow>
                    <th>Issuance Date:</th>
                    <td>{issuanceDate || ''}</td>
                  </AttributeRow>
                  <AttributeRow>
                    <th>Device Type:</th>
                    <td>{attributes.device_type || ''}</td>
                  </AttributeRow>
                </tbody>
              </AttributeTable>
            </>
          )
        } else if (attributes.strain_score) {
          const dataDate = attributes.data_date
            ? DateTime.fromSeconds(
                parseInt(attributes.data_date)
              ).toLocaleString()
            : ''
          const issuanceDate = attributes.issuance_date
            ? DateTime.fromSeconds(
                parseInt(attributes.issuance_date)
              ).toLocaleString()
            : ''

          return (
            <>
              <h2>Presentation Attributes</h2>
              <AttributeTable>
                <tbody>
                  <AttributeRow>
                    <th>Strain Score:</th>
                    <td>{attributes.strain_score || ''}</td>
                  </AttributeRow>
                  <AttributeRow>
                    <th>Heart Rate:</th>
                    <td>{attributes.heart_rate || ''}</td>
                  </AttributeRow>
                  <AttributeRow>
                    <th>Calories Burned:</th>
                    <td>{attributes.calories_burned || ''}</td>
                  </AttributeRow>
                  <AttributeRow>
                    <th>HR Zone 2nd Time:</th>
                    <td>{attributes.hr_zone_2_time || ''}</td>
                  </AttributeRow>
                  <AttributeRow>
                    <th>HR Zone 3rd Time:</th>
                    <td>{attributes.hr_zone_3_time || ''}</td>
                  </AttributeRow>
                  <AttributeRow>
                    <th>HR Zone 4th Time:</th>
                    <td>{attributes.hr_zone_4_time || ''}</td>
                  </AttributeRow>
                  <AttributeRow>
                    <th>Sleep Duration:</th>
                    <td>{attributes.sleep_duration || ''}</td>
                  </AttributeRow>
                  <AttributeRow>
                    <th>Perceived Exertion:</th>
                    <td>{attributes.perceived_exertion || ''}</td>
                  </AttributeRow>
                  <AttributeRow>
                    <th>Active Minutes:</th>
                    <td>{attributes.active_minutes || ''}</td>
                  </AttributeRow>
                  <AttributeRow>
                    <th>Data Date:</th>
                    <td>{dataDate || ''}</td>
                  </AttributeRow>
                  <AttributeRow>
                    <th>Issuance Date:</th>
                    <td>{issuanceDate || ''}</td>
                  </AttributeRow>
                  <AttributeRow>
                    <th>Device Type:</th>
                    <td>{attributes.device_type || ''}</td>
                  </AttributeRow>
                </tbody>
              </AttributeTable>
            </>
          )
        } else {
          return (
            <>
              <h2>Presentation Attributes</h2>
              <p>Attributes could not be handled!</p>
            </>
          )
        }
      }
    }

    return (
      <div id="contact">
        <PageHeader
          title={
            'Presentation request from ' + presentationSelected.contact_label ||
            ''
          }
        />
        <PageSection>
          <h2>Presentation Information</h2>
          <AttributeTable>
            <tbody>
              <AttributeRow>
                <th>Contact Label:</th>
                <td>{presentationSelected.contact_label || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Connection ID:</th>
                <td>{presentationSelected.connection_id || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Initiator:</th>
                <td>{presentationSelected.initiator || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Presentation Exchange ID:</th>
                <td>{presentationSelected.presentation_exchange_id || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Request Name:</th>
                <td>{requestName || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Role:</th>
                <td>{presentationSelected.role || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>State:</th>
                <td>{presentationSelected.state.replaceAll('_', ' ') || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Thread ID:</th>
                <td>{presentationSelected.thread_id || ''}</td>
              </AttributeRow>
              <AttributeRow>
                <th>Date Updated:</th>
                <td>
                  {new DateTime.fromISO(
                    presentationSelected.presentation_updated_at
                  ).toLocaleString(DateTime.DATETIME_MED) || ''}
                </td>
              </AttributeRow>
              <AttributeRow>
                <th>Date Created:</th>
                <td>
                  {new DateTime.fromISO(
                    presentationSelected.presentation_created_at
                  ).toLocaleString(DateTime.DATETIME_MED) || ''}
                </td>
              </AttributeRow>
            </tbody>
          </AttributeTable>
          {handleAttrDisplay()}
        </PageSection>
      </div>
    )
  } else {
    return (
      <div id="contact">
        <PageHeader title={'Presentation request'} />
        <PageSection>
          <h2>Presentation Information</h2>
        </PageSection>
      </div>
    )
  }
}

export default Presentation
